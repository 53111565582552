.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* ___________________ */
body{
  font-family: Roboto, Helvetica, sans-serif;
  overflow: auto;
}

/* @font-face {
  font-family: "airbeat";
  src: url("raw\ fonts/raw\ fonts/Airbeat.otf") format("opentype");
}

@font-face {
  font-family: "airbeat";
  font-weight: bold;
  src: url("raw\ fonts/Airbeat.otf") format("opentype");
} */

#header{
  font-family: "Helvetica", "Impact";
}

#title{
  /* color:red */
}

#titleOverlay{
  background-color: white;
  position: absolute;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  /* height: 10vh; */
  left: 80%;
  top:5vh;
  transform: translate(-50%, 0%);
  border-radius: 20%;
  text-align: center;
}


.fadeIn{
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.fadeOut{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

/* 
.col-10 {
  flex-shrink: 1;
  transition: all 400ms ease;
}

.col-12 {
  width: 0; 
  flex-grow: 1;
  transition: all 400ms ease;  
} */
