.flex-container {
    /* We first create a flex layout context */
    display: flex;
    
    /* Then we define the flow direction 
       and if we allow the items to wrap 
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */
    flex-flow: row wrap;
    
    /* Then we define how is distributed the remaining space */
    justify-content: start;
    
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .flex-item {
    /* background: tomato; */
    padding: 5px;
  }
    
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }