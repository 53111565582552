.sidebar {
    z-index: 100;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #ffff;
    /* position: absolute; */
    height: 100%;
    overflow: auto;
    text-align: center;
  }
  
  .navbar{
    --bs-gutter-x: 0rem;
    overflow: hidden;
  }
  .navbar .section{
    width: 100vw;
  }
  .navbar a{
    width: 100vw;
    padding: 0;
  }
  
  .subPath{
    padding: 8px !important;
    padding-left: 20% !important;
  }

  .sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
  }
   
  .sidebar a.active {
    background-color: grey;
    color: white;
  }
  
  .sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
  }
  
  div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  }

  .collapsed{
    display: none;
  }
  
  /* @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar .section {float: left;}
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
  } */